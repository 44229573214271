import React from "react"
import EventLayout from "../../../components/layouts/eventLayout"
import Button from "../../../components/button"

const CurisinInTheCountryAccomodeationsPage = props => (
  <EventLayout
    pageTitle="Cruisin’ in the Country"
    tabs={[
      { to: "/programs/cruisin-in-the-country", text: "Overview" },
      {
        to: "/programs/cruisin-in-the-country/routes",
        text: "Routes",
      },
      {
        to: "/programs/cruisin-in-the-country/registration",
        text: "Registration",
      },
      {
        to: "/programs/cruisin-in-the-country/accomodations",
        text: "Accommodations",
      },
      //{
      //  to: "/programs/cruisin-in-the-country/run",
      //  text: "Run",
      //},
    ]}
  >
    <h2 css={{marginBottom: `2rem`}}>Accommodations</h2>
    <p>
      Cruisin’ Headquarters has moved this year. This year’s event will be headquartered at the Evans County Recreation
Department located 1.2 miles east of Claxton on Highway 280. Physical Address – 11093 Highway 280 East,
Claxton, Georgia 30417. Some may remember that this is where Cruisin’ was hosted for many years prior to the
move to the Evans County Public Fishing Area in 2021.
    </p><br /><br />
    <p>
The Evans County Public Fishing Area, 4757 Area Line Road, Claxton, Georgia. This beautiful 372- acre site is
owned and operated by the Georgia Department of Natural Resources and features a campground with 22 campsites
complete with electricity, water, picnic table, fire ring and a comfort station with showers. Primitive campsites are
also available. For camping information or to make a reservation, log on to
<a href="http://reservations.gooutdoorsgeorgia.com">http://reservations.gooutdoorsgeorgia.com</a>.
  </p><br />
    <p>
    Fort-C Truck Park and Campground, located at 325 North Duval Street, Claxton, GA 30417, only 8 miles from
Cruisin’ Headquarters. The Park provides overnight full hookup sites for some style RV campers. Reservations can
be made at Campsot.com, look for Claxton GA. Five full hookups, one tent site, and one tiny cabin are located on
site. Restrooms provided. Call for information (912) 237-3608.
      <br /><br />
      Catfish Creek RV Campground is located just 10 miles from Claxton on Highway 301 North with full hook-ups. Call (912) 654-2278 for information. Address: 12576 U.S. Hwy. 301 Glennville, GA 30427<a href="http://www.catfishcreekrv.com ">http://www.catfishcreekrv.com </a>.
      <br /><br />
      The Jack Hill State Park in Reidsville is a short 14 miles to the west of Claxton on Highway 280 and offers tent and
RV sites. The George L. Smith State Park in Twin City is just 32 miles from Claxton.
Address: 162 Park Lane Reidsville, GA 30453 Tattnall County
Park 912-557-7744 Reservations 800-864-7275 <a href="https://gastateparks.org/JackHill">https://gastateparks.org/JackHill</a>.  
      <br /><br />
     Beaver Run RV Park, located off I-16 just 14 miles from Claxton, offers 28 acres of camping with full hook-ups,
cable, and free Wi-Fi
Address: I-16, South at exit 111, 22321 Excelsior Church Rd, Metter, GA 30439
Phone: (912) 362-4737 <a href="https://beaverrunrvpark.com/">https://beaverrunrvpark.com/</a>.  
      <br /><br />
      Parkwood RV Park, located on Highway 301 South in Statesboro, only 19 miles from Claxton offers full hook-ups
and a host of other amenities.
Address: 12188 US-301, Statesboro, GA 30458
Phone: (912) 681-3105
Email: parkwood@parkwood.com or visit <a href="www.parkwoodrv.com">www.parkwoodrv.com</a>. 
    </p>
    <h3 css={{fontSize: `1.3rem`, marginTop: `2rem`}}>Hotel Accommodations</h3>
    <div css={{
      display: `flex`,
      flexWrap: `wrap`,
      '& div': {
        padding: `2rem 3rem`,
        minWidth: `284px`,
      }  
    }}>
      <div>
        <p>
          <b>Northside Inn</b><br />
          8305 Highway 301, North<br />
          Claxton, GA  30417<br />
          (912) 739-4485
        </p>
      </div>
      <div>
        <p>
          <b>Relax Inn</b><br />
          7903 US Highway 301, North<br />
          Claxton, GA  30417<br />
          (912) 290-5254
        </p>
      </div>
      <div>
        <p>
          <b>Oak Tree Inn & Suites</b><br />
          10 North Duval Street<br />
          Claxton, GA  30417<br />
          (912) 290-5250
        </p>
      </div>
    </div>
    <div>
    <a css={{
        display: "inline-block",
        marginTop: "0.66rrem",
        backgroundColor: "rgb(9, 37, 86)",
        color: "#fff",
        textDecoration: "none",
        padding: "0.667rem",
        borderRadius: "2px",
        marginRight: `1.5rem`,
      }} href="/docs/2024CruisinCampingLodgingInformation.pdf">Accommodations Printable PDF</a>
    </div>
  </EventLayout>
)

export default CurisinInTheCountryAccomodeationsPage
